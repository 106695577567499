import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["ComponentSearch"] */ "/vercel/path0/src/components/ComponentSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ScrollTopAndComment.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/data/logo.svg");
