import { siteMetadata } from "@/data/siteMetadata"
import { AlgoliaButton } from "pliny/search/AlgoliaButton.js"
import { KBarButton } from "pliny/search/KBarButton.js"

const SearchButton = () => {
  if (
    siteMetadata.search &&
    (siteMetadata.search.provider === "algolia" || siteMetadata.search.provider === "kbar")
  ) {
    const SearchButtonWrapper =
      siteMetadata.search.provider === "algolia" ? AlgoliaButton : KBarButton

    return (
      <SearchButtonWrapper
        aria-label="Search"
        role="button"
        aria-labelledby="Search"
        title="Search"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6 text-gray-900 dark:text-gray-100"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
      </SearchButtonWrapper>
    )
  }
}

export default SearchButton
